<template>
  <div>
    <q-form ref="editForm">
      <div class="col-12">
        <c-text
          :required="true"
          :editable="false"
          label="아이디"
          name="loginId"
          v-model="data.loginId">
        </c-text>
      </div>
      <div class="col-12">
        <c-text
          :required="true"
          label="기존 비밀번호"
          name="oripassword"
          type="password"
          v-model="data.oripassword">
        </c-text>
      </div>
      <div class="col-12">
        <c-text
          :required="true"
          label="변경 비밀번호"
          name="password"
          type="password"
          v-model="data.password">
        </c-text>
      </div>
      <div class="col-12">
        <c-text
          :required="true"
          label="변경 비밀번호 확인"
          name="passwordConf"
          type="password"
          v-model="data.passwordConf">
        </c-text>
      </div>
      <div class="col-12" style="text-align:right">
        <c-btn label="비밀번호 변경" icon="save" @btnClicked="savePw" />
      </div>
    </q-form>
  </div>
</template>
<script>
export default {
  name: 'user-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          loginId: '',
        }
      },
    },
  },
  data() {
    return {
      data: {
        loginId: '',
        oripassword: '',
        password: '',
        passwordConf: '',
        chgUserId: '',
      },
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.data.loginId = this.popupParam.loginId;
      this.saveUrl = 'api/auth/user/changepw';
    },
    savePw() {
      if (this.data.oripassword === '' || this.data.password === '' || this.data.passwordConf === '') {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '비밀번호를 입력하세요!',
          type: 'warning', // success / info / warning / error
        });
      } else if (this.data.password !== this.data.passwordConf) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '변경비밀번호와 비밀번호 확인이 다릅니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '변경하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {          
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = this.data;
            this.$http.request((_result) => {
              if (_result.data === 'W') {
                window.getApp.$emit('ALERT', {
                  title: '안내', // 안내
                  message: '기존 비밀번호가 틀립니다.',
                  type: 'warning', // success / info / warning / error
                });
              } else {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.$emit('closePopup');
              }
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
